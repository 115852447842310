import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

/**
 *
 * @param {*} props : properties received from the parent
 *
 * This component,
 * @returns : a set of radio button for a provided list of option choices
 *
 *Remark: Explaination of other params.
 * @param label :  the label prop.
 * @param name :  the name prop.
 * @param options : an array of object {key: "label for individual radio", value:"corresponding value "}
 *                  each object represents a radio choice.
 * @param {...rest} : is the sread of the remaining received props.
 *
 * @param field : is a Formik object containing (onChange, onBlur, name, value)
 *  of the Formik entire Field component.(so we don't need to input the name prop. for each radio)
 */
export default function Radio(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div className="form-control">
      {/* label for the overall component */}
      <label>{label} </label>
      {/* Let's use the render props pattern for the Field component to render a list of input and labels paires for our radios*/}
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option) => {
            return (
              // we return a fragment since we cannot return multiple elements (here input and label)
              <React.Fragment key={option.key}>
                <input
                  type="radio"
                  id={option.value}
                  {...field}
                  // overwrite the value prop. to individual radio's value. since the value in field is for the entire form Field
                  value={option.value}
                  //to keep track of the checked radio button , Verify if the value of the field = value of individual radio
                  checked={field.value === option.value}
                />
                <label htmlFor={option.value}>{option.key} </label>
              </React.Fragment>
            );
          });
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
