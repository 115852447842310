import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

/**
 *
 * @param {*} props : properties received from the parent
 *
 *This Select component,
 * @returns : a Formik  select options field with appropriate props.
 */
export default function Select(props) {
  //label, name, options are props. that must be passed in the Formik Field component.
  // options : is a list of dropdown select options to be specified as a const.
  const { label, name, options, ...rest } = props;
  return (
    <div className="form-control">
      <label htmlFor={name}> {label} </label>
      <Field as="select" id={name} name={name} {...rest}>
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.key}
            </option>
          );
        })}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
