import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import {AssService} from '../../services/AssService'
import UserService from '../../services/UserService'

import UserNav from './UserNav'

import Popup from 'reactjs-popup'
//import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { USER_ROLES } from '../../utils/userRoles'
//import FormikControl from '../../components/formikComponents/FormikControl'
import { Formik, Field, Form } from 'formik';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import dates from '../../utils/dates'

function UserProfile ({ i18n, t }) {
  const [dataUser, setdataUser] = useState(null)
  const { userId } = useParams()
  const [requestData, setRequestData] = useState([])
  const [mobilesList, setMobilesList] = useState([])
  const [dummyState, setDummyState] = useState(0);
  const [SAVState, setSAVState] = useState(0);
  const [initialValues,setInitialValues] = useState({login: '',roles: [],})
  //const [requestPhones, setRequestPhones] = useState(null)

  const navigateToUsersPage = useNavigate()
  const navigate = useNavigate()

  const passwordRules = /.{4,20}$/;
  // min 4 characters, max 20 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
  // const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

  // refresh toutes les 10s du mode SAV
  useEffect(() => {
    const timeoutId = setInterval(() => {
      setSAVState(Date.now())
      console.log('update');
    }, 10000);

    return () => clearInterval(timeoutId);
  }, []);

  useEffect(() => {
    UserService.getUser(userId).then((resource) => {
      setdataUser(resource)
    }).catch((error) => {
      console.log(error)
    })
  }, [userId])

  useEffect(() => {
    if (dataUser != null) {      
      initialValues.login = dataUser.login;

      var checkboxOptions = USER_ROLES
      for (var i=0; i < checkboxOptions.length;i++)
      {
        if(dataUser?.roles.includes(checkboxOptions[i].key))
        {
          checkboxOptions[i].checked = true;
        }
        else
        {
          checkboxOptions[i].checked = false;
        }
      }

      initialValues.roles = checkboxOptions;

      let query = {}
      query['sift:userId'] = dataUser.id
      AssService.getMobiles(query).then((response) => {
        setMobilesList(response.data)
      })
    }
  }, [dataUser])

  useEffect(() => {
    if (dataUser != null) {

      AssService.getSavRequestsStatus({'sift:userId': dataUser.id}).then((response) => {
        setRequestData(response?.data[0])
      })
    }
  }, [SAVState])

  useEffect(() => {},[mobilesList]);

  const handleBackUserList = () => {
    navigateToUsersPage('/users')
  }

  const handleDelete = (id) => {
    UserService.deleteUser(id)
               .then((res) => {
                 handleBackUserList()
               })
               .catch((err) => {
                 console.log(err)
               })
  }

  const deleteMobile = (id) => {
    //mobilesList.splice(id,1)
    AssService.deleteObject(mobilesList[id].id).then(()=>{
      var temp = mobilesList
      temp.splice(id,1)
      setMobilesList(temp)
      setDummyState(Date.now())
    })
  }


  const validationSchema = Yup.object({
    password: Yup.string()
    .required('No password provided.') 
    .matches(passwordRules, { message: "Please create a stronger password" }),
    verif: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
  })

  const onSubmit = async (values) => {
    var roles = []

    values.roles.map((role,index) => {
      if (role.checked)
        {
          roles.push(role.value);
        }
    })
    
    UserService.updateUser(dataUser.id, {login:values.login, roles: roles})
               .then((response) => {
                 //navigate('/users')
               })
               .catch((error) => {
                 console.log(error)
               })
  }

  const onPasswordSubmit = async (values) => {

    UserService.updateUserPassword(dataUser.id, {password:values.password})
               .then((response) => {
                 navigate('/users')
               })
               .catch((error) => {
                 console.log(error)
               })
  }

  return (
    <>
      <UserNav title={t('users.profile.title')} />
      <div className="content">

        <button className='deleteBox floating-right'>
            <div className='deleteProfile'>
              <Popup trigger={<p className='deleteButtonLabel'>Supprimer le compte</p>} modal nested>
                {close => (
                  <div className='deleteFullBox'>
                    <div className='deleteBoxTitle'>
                      <h3>Êtes-vous sûr de vouloir supprimer ce compte?</h3>
                    </div>
                    <div className='deleteBoxContent'>
                      <p>Le compte sera définitivement supprimé.</p>
                      <p>Tout les objets liés seront aussi supprimés.</p>
                    </div>
                    <div className='deleteBoxButtons'>
                      <button className='stopDelete' onClick={close}>Annuler</button>
                      <button className='validateDelete' onClick={() => handleDelete(dataUser.id)}>Valider</button>
                    </div>
                  </div>
                )}
            </Popup>
          </div>
        </button>
        <span className="floating-cancel"></span>

        <div className="row">
          <div className="col">
              <div className='profil'>
                <h6 className="floating-button">{t('users.profile.information')}</h6>
                <p className="floating-cancel"><span className="title">{t('users.profile.username')} : </span>{ (dataUser?.name)}</p>
                <p><span className="title">{t('users.profile.last_connection')} : </span>
                  { (dataUser?.lastSeen != null) ? 
                    new Intl.DateTimeFormat('fr', { dateStyle: 'full', timeStyle: 'long' }).format(
                      new Date(1000 * Number(dataUser.lastSeen))) : '--' }</p>
                <div>
                  <span className="title">{t('users.profile.mobiles')} : </span>
                  {
                    mobilesList?.map((mobile,index)=>{
                        return(
                          <Chip 
                            key={mobile?.userName} 
                            label={mobile?.userName} 
                            sx={dummyState}
                            variant="outlined" 
                            deleteIcon={<DeleteIcon />}
                            onDelete={(e) => deleteMobile(index) }
                          />
                        )
                      })
                  }
                </div>
              </div>
          </div>

          <div className="col">
            <div className="profil">
              <div className='fullBox'>
                <h6>{t('users.profile.ass')}</h6>
                <div className='box'>
                    <p><span className="title">Statut : </span><span class="ass_state">{(requestData == null ? 'Aucune demande' : `${requestData.state}`)}</span></p>
                    <p><span className="title">Temps restant : </span>{(requestData == null ? 'Aucune demande' : `${requestData.duration} seconde(s)`)}</p>
                    <p><span className="title">Demande précédente : </span>{(requestData == null ? 'Aucune demande' : dates.format(i18n, requestData.creationTimestamp))}</p>
                </div>
                <button className='savRequest button' onClick={() => {
                    ((requestData == null)||(requestData.state==='CANCELED')||(requestData.state==='INTERRUPTED'))?AssService.sendSavRequest(dataUser.id).then(window.confirm('Requête envoyée!')):AssService.cancelSavRequest(requestData.id);
                    setSAVState(Date.now());
                  }}
                >
                    {((requestData == null)||(requestData.state==='CANCELED')||(requestData.state==='INTERRUPTED'))?"Envoyer une demande":"Annuler la demande"}
                </button>
              </div>
            </div>
          </div>
        </div>


        
        <div className="row">
          <div className="col">
          
          <div className='profil'>
            <h6>{t('users.profile.update')}</h6>
            
            <Formik
              initialValues= {initialValues}
              onSubmit={onSubmit}
            >
              {({values}) => (
                <Form >
                  <p className='editBox'>
                    <label htmlFor="login">{t('users.profile.field.login')}</label>
                    <Field
                      className='editInput'
                      name='login'
                    />
                  </p>
                  <p className='editRolesBox'>
                    <h6>Roles</h6>
                    {values.roles.map((box,index) => {
                      return(
                        <>
                          <Field
                            checked={box.checked}
                            type='checkbox'
                            name='roles'
                            value={box.value}
                            onChange={()=>{values.roles[index].checked = !box.checked;setDummyState(!dummyState)}}    
                          />
                          <label htmlFor="roles">{box.key}</label>
                        </>
                      )
                    })}
                  </p>
                  <div className='editButtons'>
                    <button type='submit' className='checkIn'>
                      Enregistrer
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="col">
          <div className='profil'>
            <h6>{t('users.profile.password')}</h6>
            <Formik
              initialValues={{password:'',verif:''}}
              validationSchema={validationSchema}
              onSubmit={onPasswordSubmit}
            >
              {(props) => (
                <Form >
                  <p className='editBox'>
                    <label htmlFor="password">{t('users.profile.field.password')}</label>
                    <Field
                      className='editInput'
                      name='password'
                    />
                    {props.errors.password && props.touched.password ? (
                      <div>{props.errors.password}</div>
                    ) : null}
                  </p>
                  <p className='editBox'>
                    <label htmlFor="verif">{t('users.profile.field.verif')}</label>
                    <Field
                      className='editInput'
                      name='verif'
                    />
                    {props.errors.verif && props.touched.verif ? (
                      <div>{props.errors.verif}</div>
                    ) : null}
                  </p>
                  <div className='editButtons'>
                    <button type='submit' className='checkIn'>
                      Enregistrer
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation('ui')(UserProfile)
