import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'

import DisClient from '../../services/SupervisionService'

import StatusCard from '../../components/cards/StatusCard'

//import './dashboard.css'

const Dashboard = ({t}) => {
  const [connectedGwCount, setConnectedGwCount] = useState('--')
  const [connectedProbesCount, setConnectedProbesCount] = useState('--')
  const [devicesCount, setDevicesCount] = useState('--')
  const [gwCount, setGwCount] = useState('--')
  const [gwPoints, setGwPoints] = useState([])
  const [usersCount, setUsersCount] = useState('--')
  const [usersPoints, setUsersPoints] = useState([])

  const [connectedPfxObjectsCount, setConnectedPfxObjectsCount] = useState('--')


  useEffect(() => {
    /* Dashboard graphs show the dynamics on the last 30 days. */
    const graphTo = new Date().getTime() / 1000
    const graphFrom = graphTo - (30 * 24 * 60 * 60)

    DisClient.getStats()
             .then(response => {
               setConnectedGwCount(response.gateways?.licenced.active)
               setDevicesCount(response.objects)
               setGwCount(response.gateways?.licenced.known)
               setUsersCount(response.users)
             })
             .catch((err) => {
               console.warn('Dis Error.', err.message)
               setConnectedGwCount('--')
               setDevicesCount('--')
               setGwCount('--')
               setUsersCount('--')
             })
    DisClient.getGatewaysHistory(graphFrom, graphTo, 30)
             .then(response => {
               setGwPoints(response.data)
             })
             .catch((err) => {
               console.warn('Dis Error.', err.message)
             })
    DisClient.getObjectsCount({classes: ["Sonde"]})
             .then(response => {
               setConnectedProbesCount(response.count)
             })
             .catch((err) => {
               console.warn('Dis Error.', err.message)
               setConnectedProbesCount('--')
             })
    DisClient.getUsersHistory(graphFrom, graphTo, 30)
             .then(response => {
               setUsersPoints(response.data)
             })
             .catch((err) => {
               console.warn('Dis Error.', err.message)
             })

    /* This one is dedicated to Profalux */
    DisClient.getObjectsCount({types: [
      "Gate_Ezsp", "Gate_Toggle_Ezsp", "Garage_Door_Ezsp", "Garage_Door_Toggle_Ezsp",
      "Awning_Ezsp", "Rolling_Shutter_Ezsp", "Shade_Ezsp", "Venetian_Shutter_Ezsp",
      "Rolling_Shutter_Profalux"]})
             .then(response => {
               setConnectedPfxObjectsCount(response.count)
             })
             .catch((err) => {
               console.warn('Dis Error.', err.message)
               setConnectedPfxObjectsCount('--')
             })
  }, [])

  return (
    <div className='dashboard'>
      <StatusCard
        icon='bx bx-group'
        data={usersPoints}
        title={t('dashboard.cards.users')}
        value={usersCount}
      />
      <StatusCard
        icon='bx bx-chip'
        title={t('dashboard.cards.connected_devices')}
        value={devicesCount}
      />
      <StatusCard
        icon='bx bx-chip'
        title={t('dashboard.cards.connected_probes')}
        value={connectedProbesCount}
      />
      <StatusCard
        icon='bx bx-chip'
        value={connectedPfxObjectsCount}
        title={t('dashboard.cards.connected_pfx_devices')}
      />
      <StatusCard
        icon='bx bx-network-chart'
        data={gwPoints}
        title={t('dashboard.cards.connected_gateways')}
        value={connectedGwCount}
      />
      <StatusCard
        icon='bx bx-network-chart'
        value={gwCount}
        title={t('dashboard.cards.gateways')}
      />
    </div>
  )
}

export default withTranslation('ui')(Dashboard)
