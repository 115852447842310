import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import config from '../../config'
import Select from 'react-select'
import {AssService} from '../../services/AssService'

/*--
    action[0] : pop state parameter
    action[1] : userId
    action[2] : function to call when gateway is added
--*/
const AddGatewayPopUp = ({action,t}) => {
  
    const [openPopup,setOpenPopup] = useState (false);
    const [id, setId] = useState('');
    const [name, setName] = useState('Gateway zigbee');

    useEffect(()=>{
            setOpenPopup(action[0]);
    },[action[0]])

    function onAdd() {

        var body = {
            "gateway": {
                "name": name,
                "key": id
            },
            "userId": parseInt(action[1])
        }

        console.log(body);

        try {
          return AssService.createGateway(body).then(()=>{action[0] = false;action[2]()})
        } catch (e) {
          throw new Error(`API error:${e?.message}`)
        }    
    }

    return (
        <Popup open={openPopup} closeOnDocumentClick modal className='addPopUp'>
            <h5>{t('users.gateways.add.title')}</h5>
            <input
                placeholder={t('users.gateways.add.placeholder.id')}
                type='text'
                value={id}
                onChange={(e) =>setId(e.target.value)}
            />
            <input
                placeholder={t('users.gateways.add.placeholder.name')}
                type='text'
                value={name}
                onChange={(e) =>setName(e.target.value)}
            />
            <button className="button" onClick={()=>{setOpenPopup(false)}}>{t('objects.add.cancel')}</button>
            <button className="button" onClick={onAdd}>{t('objects.add.validate')}</button>
        </Popup>
    );
    
}
  
export default AddGatewayPopUp;