const SidebarItem = (props) => {
	const active = props.active ? 'sidebar-item-active' : ''
	return (
		<div className={`sidebar-item ${active}`}>
		  <i className={props.icon}></i>
		  <span>{props.title}</span>
		</div>
	)
}

export default SidebarItem
