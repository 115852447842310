import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "../../components/dropdown/Dropdown";
import NOTIFICATIONS from "../../utils/notifications";

import useAuth from "../../utils/hooks/useAuth";
import USER_MENU from "../../utils/userTopnavMenu";

const Topnav = () => {
	const { auth, logout } = useAuth();
	const [currUser, setCurrUser] = useState({});

	// useEffect(() => {
	// 	setCurrUser({
	// 		displayName: auth?.login,
	// 		shortName: (auth?.userName != null)? Array.from(auth.userName)[0] : ''
	// 	});
	// }, [auth]);

	// Render notifications
	const renderNotificationItem = (item, index) => (
		<div className="notification-item" key={index}>
			<i className={item.icon}></i>
			<span>{item.content}</span>
		</div>
	);

	// Render current user toggle
	const renderUserToggle = (user) => (
//		<div className="topnav-right-user">{user.shortName}</div>
		<div className="topnav-right-user">{(auth?.userName != null)? Array.from(auth.userName): ''}</div>
	);

	const renderUserMenu = (item, index) => {
		if (item.content.toLowerCase() === "logout") {
			return (
				<Link
					to="/admin"
					onClick={logout}
					key={index}
				>
					<div className="notification-item">
						<i className={item.icon}></i>
						<span>{item.content}</span>
					</div>
				</Link>
			);
		} else if (item.content.toLowerCase() === "profile") {
			return (
				//"/users/" + auth.userId
				<Link to={"/profile"} key={index}>
					<div className="notification-item">
						<i className={item.icon}></i>
						<span>{item.content}</span>
					</div>
				</Link>
			);
		} else {
			return (
				<Link to={"/" + item.content.toLowerCase()} key={index}>
					<div className="notification-item">
						<i className={item.icon}></i>
						<span>{item.content}</span>
					</div>
				</Link>
			);
		}
	};

	return (
		<div className="topnav">
			<div className="topnav-search">
				{/* 
				<input type="text" placeholder="Search here..." />
				<i className="bx bx-search"></i>*/}
			</div>
			<div className="topnav-right">
				{auth ? (
					<React.Fragment>
						<div className="topnav-right-item">
							<Dropdown
								icon="bx bx-bell"
								badge="4"
								contentData={NOTIFICATIONS}
								renderItems={(item, index) =>
									renderNotificationItem(item, index)
								}
								renderFooter={() => <Link to="/"> View All</Link>}
							/>
						</div>
						<div className="topnav-right-item">
							<Dropdown
								customToggle={() => renderUserToggle()}
								contentData={USER_MENU}
								renderItems={(item, index) => renderUserMenu(item, index)}
								showUser
							/>
						</div>
					</React.Fragment>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default Topnav;
