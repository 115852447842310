import { forwardRef, useEffect, useImperativeHandle, useReducer, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import TableControl from './control'

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

import 'boxicons'

const TableComponent = forwardRef(({columns, feeder, keyword, externalTrigger, t, initialQuery={page: 0, size: 10}}, ref) => {

  const [queryState, dispatch] = useReducer(TableControl.reducer, TableControl.initState(initialQuery))

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: initialQuery.page - 1,
    pageSize: initialQuery.size,
  })
  const [sorting, setSorting] = useState([])

  const query = useQuery(['keys', queryState, externalTrigger], () => { return feeder(queryState) }, {
    keepPreviousData: false,
    staleTime: Infinity,
  })

  const table = useReactTable({
    columns,
    data: query.data?.data || [],
    state: {
      pagination: {
        pageIndex: queryState.pageIndex,
        pageSize: queryState.pageSize,
      },
      sorting //: [queryPageSortBy]
    },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    debugAllTable: false,
    manualPagination: true,
    manualSorting: true,
    pageCount: query.data ? query.data.count : null
  })

  useImperativeHandle(ref, () => {
    return {
      reload : () => {
        query.refetch()
      }
    }
  }, [query])

  useEffect(() => {
    dispatch({ type: TableControl.action.PAGE_CHANGED, payload: pageIndex })
  }, [pageIndex])

  useEffect(() => {
    dispatch({ type: TableControl.action.PAGE_SIZE_CHANGED, payload: pageSize })
    table.setPageIndex(0)
  }, [pageSize, table])

  useEffect(() => {
    dispatch({ type: TableControl.action.PAGE_SORT_CHANGED, payload: sorting })
    table.setPageIndex(0)
  }, [sorting, table])

  useEffect(() => {
      dispatch({ type: TableControl.action.PAGE_FILTER_CHANGED, payload: keyword })
      table.setPageIndex(0)
  }, [keyword, table])

  if (query.isError) {
    return <p>Error {query.error.message}</p>
  }

  if (query.isLoading) {
    return <p>Loading...</p>
  }

  if (query.isSuccess) {
    return (
      <>
        <div>
          <table className='table'>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr className='table-head-row' key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'pointer' : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' 🠝',
                              desc: ' 🠟',
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        )}
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {
                table.getRowModel().rows.map(row => {
                  return (
                    <tr className='table-body-row' key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
              }

            </tbody>
          </table>
          <div className='table-nav-row'>
            <div className='table-nav-position'>
              {t('table.footer.page', {
                'index': table.getState().pagination.pageIndex + 1,
                'count': table.getPageCount()
              })}
            </div>
            <div className='table-nav-control'>
              <p>{t('table.footer.display')}</p>
              <select className='selector'
                      onChange={e => { table.setPageSize(Number(e.target.value)) }}
                      value={table.getState().pagination.pageSize}>
                {
                  [10, 25, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>{pageSize}</option>
                  ))
                }
              </select>
              <button className='button'
                      onClick={() => table.setPageIndex(0)}
                      disabled={!table.getCanPreviousPage()}>
                <box-icon name='arrow-to-left'></box-icon>
              </button>
              <button className='button'
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}>
                <box-icon name='left-arrow-alt'></box-icon>
              </button>
              <button className='button'
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}>
                <box-icon name='right-arrow-alt'></box-icon>
              </button>
              <button className='button'
                      onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                      disabled={!table.getCanNextPage()}>
                <box-icon name='arrow-to-right'></box-icon>
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
})

export default withTranslation('components', { withRef: true })(TableComponent)
