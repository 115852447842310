const USER_MENU = [
	{
		icon: "bx bx-user",
		content: "Profile",
	},
	{
		icon: "bx bx-log-out-circle bx-rotate-180",
		content: "Logout",
	},
];

export default USER_MENU;
