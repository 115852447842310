const action = Object.freeze({
  PAGE_CHANGED: 'PAGE_CHANGED',
  PAGE_SIZE_CHANGED: 'PAGE_SIZE_CHANGED',
  PAGE_SORT_CHANGED: 'PAGE_SORT_CHANGED',
  PAGE_FILTER_CHANGED: 'PAGE_FILTER_CHANGED'
})

const TableControl = {

  action,

  initState: function (query) {
    return Object.freeze({
      pageFilter: '',
      pageIndex: query?.page || 0,
      pageSize: query?.size || 10,
      pageSortBy: []
    })
  },

  reducer: (state, { type, payload }) => {
    switch (type) {
      case action.PAGE_FILTER_CHANGED:
        return { ...state, pageFilter: payload }
      case action.PAGE_CHANGED:
        return { ...state, pageIndex: payload }
      case action.PAGE_SIZE_CHANGED:
        return { ...state, pageSize: payload }
      case action.PAGE_SORT_CHANGED:
        return { ...state, pageSortBy: payload }
      default:
        throw new Error(`Unhandled action type: ${type}`)
    }
  }
}

export default TableControl
