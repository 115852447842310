import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

/**
 *
 * @param {*} props : properties received from the parent
 *
 * This component,
 * @returns : a set of radio button for a provided list of option choices
 *
 *Remark: Explaination of other params.
 * @param label :  the label prop.
 * @param name :  the name prop.
 * @param options : an array of object {key: "label for individual radio", value:"corresponding value "}
 *                  each object represents a radio choice.
 * @param {...rest} : is the sread of the remaining received props.
 *
 * @param field : is a Formik object containing (onChange, onBlur, name, value)
 *  of the Formik entire Field component.(so we don't need to input the name prop. for each radio)
 */
export default function CheckboxGroup(props) {
	const { label, name, options, ...rest } = props;
	const [dummy,setDummy] = useState(false);

	return (
		<div className="checkbox-control">
			{/* label for the overall component */}
			<label>{label} </label>
			{/* Let's use the render props pattern for the Field component to render a list of input and labels paires for our radios*/}
			<div className="checkbox-control-field ">
				<Field name={name} {...rest}>
					{({ field }) => {
						return options.map((option,index) => {
							return (
								// we return a fragment since we cannot return multiple elements (here input and label)
								<React.Fragment key={option.key}>
									<label htmlFor={option.value}>
										<div className="checkbox-input">
											<input
												type="checkbox"
												id={option.value}
												{...field}
												// overwrite the value prop. to individual radio's value. since the value in field is for the entire form Field
												value={option.value}
												//to keep track of the checked boxes button , Verify if the value of checked box is present
												//in the array of values for the entire field. if present : checked is set to true.
												//checked={field.value.includes(option.value)}
												checked={option.checked/*||field.value.includes(option.value)*/}
												onChange={()=>{options[index].checked = !option.checked;setDummy(!dummy)}}
											/>
										</div>
										{option.key}
									</label>
								</React.Fragment>
							);
						});
					}}
				</Field>
			</div>
			<ErrorMessage name={name} component={TextError} />
		</div>
	);
}
