//import Popup from 'reactjs-popup';
import Modal from '@mui/material/Modal';
import {AssService} from '../../services/AssService'
import { useEffect, useState } from 'react';

/*--
    action[0] : pop state parameter
    action[1] : gateway id
    action[2] : function to call when gatewai is added
--*/
const GatewayDeletePopUp = ({action,t}) => {

    const [openPopup,setOpenPopup] = useState (0);

    useEffect(()=>{
            setOpenPopup(action[0]);
    },[action[0]])

    useEffect(()=>{
    },[openPopup])

    function onDelete() {
        try {
          return AssService.deleteGateway(action[1]).then(()=>{action[0] = false;action[2]()})
        } catch (e) {
          throw new Error(`API error:${e?.message}`)
        }    
      }
  
      return (
        <Modal open={openPopup} className='confirmPopUp'
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <div id="modalContent" className='confirmPopUp-content'>
                <h5>{t('objects.deleting.title')}</h5>
                <p>{t('objects.deleting.text')}</p>
                <button className="button" onClick={()=>{action[1](0)}}>{t('objects.deleting.cancel')}</button>
                <button className="button" onClick={onDelete}>{t('objects.deleting.validate')}</button>
            </div>
        </Modal>
    );
    
}
export default GatewayDeletePopUp;