import { DurinClient } from './apiClient'

const KeyService = {
    client: new DurinClient(),

    normalizeKey: function (resource) {
        return {
            id: resource.id,
            authorizations: resource.authorizations || [],
            changed: resource.changed || '',
            comment: resource.comment,
            created: resource.created,
            key: resource.key,
            status: resource.status,
        }
    },

    /**
     * Fetch one key from the server.
     *
     * @param {Number} id : the unique identifier of the key.
     * @returns {Promise} : a Promise that points to the response from the request
     */
    getKey: async function (id) {
        return await this.client.get(['/server/keys', id], this.normalizeKey)
    },

    /**
     * Fetch a page of keys from the server.
     *
     * @param {*} page
     * @returns
     */
    getKeys: async function (criteria) {
        return await this.client.get('/server/keys', criteria, this.normalizeKey)
    },

    /**
     * Create new keys.
     *
     * @param {Number} number : the number of keys to create with the given properties.
     * @param {Array} properties : the properties used to create the keys.
     * @returns {Promise} : a Promise that points to the response from the request
     */
    createKeys: async function (properties) {
        const result = await this.client.post('/server/keys', {
            authorizations: properties.authorizations,
            comment: properties.comment,
            number: properties.number
        }, this.normalizeKey)

        /* Map the result as an array, even if only one key was created. */
        if (result.data != null)
            return result.data
        else
            return [result]
    },

    updateKey: async function (id, properties) {
        return await this.client.put(['server/keys', id], {
            authorizations: properties.authorizations,
            comment: properties.comment
        }, this.normalizeKey)
    },
}

export default KeyService
