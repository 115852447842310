import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

/**
 *
 * @param {Object} props : proprieties received from the parent.
 *
 * This Input component,
 * @returns : a Formik  input field with appropriate props.
 */
export default function Input(props) {
	const { label, name, ...rest } = props;
	return (
		<div className="form-control">
			<label htmlFor={name}>{label}</label>
			<Field id={name} name={name} {...rest} />
			<ErrorMessage name={name} component={TextError} />
		</div>
	);
}
