/**
 * A client for Dis, the Domus Interface for Supervision.
 */
import {APIClient } from './apiClient'

const SupervisionService = {
    client: new APIClient('/services/dis'),

    /**
     * Fetch the statistics summary.
     *
     * @returns {Promise} : a Promise that points to the response from the request
     */
    getStats: async function () {
        return await this.client.get('stats')
    },

    /**
     * Fetch the count of objects, filtered by classes or types.
     *
     * @returns {Promise} : a Promise that points to the response from the request
     */
    getObjectsCount: async function (properties) {
        return await this.client.post('stats/objects', {
            classes: properties.classes,
            types: properties.types
        })
    },

    /**
     * Fetch the history of the number of gateways.
     *
     * @returns {Promise} : a Promise that points to the response from the request
     */
    getGatewaysHistory: async function (from, to, points) {
        return await this.client.post('stats/gateways', {
            start: from,
            end: to,
            points
        })
    },

    /**
     * Fetch the history of the number of users.
     *
     * @returns {Promise} : a Promise that points to the response from the request
     */
    getUsersHistory: async function (from, to, points) {
        return await this.client.post('stats/users', {
            start: from,
            end: to,
            points
        })
    },
}

export default SupervisionService
