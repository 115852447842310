import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

/**
 *
 * @param {*} props : proprieties received from the parent
 *
 * This Textarea component,
 * @returns : a Formik  text field with appropriate props.
 */
export default function Textarea(props) {
  //label, name, options are props. that must be passed in the Formik Field component.
  // Destruct  label and name props., then leave the rest
  const { label, name, ...rest } = props;
  return (
    <div className="form-control">
      <label htmlFor={name}>{label}</label>
      <Field as="textarea" id={name} name={name} {...rest} />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
