import Base64 from '../utils/base64'

const MICOCOL_CLIENT = 'dwalin'

function trim (str) {
  return str.replace(/^\s+/g, '').replace(/\s+$/g, '')
}

function trimQuotes (str) {
  return str.replace(/^\'+/g, '').replace(/\'+$/g, '')
}

function get_ws_url () {
  var protocol = 'ws://'

  /* We open the websocket encrypted if this page came on an
   * https:// url itself, otherwise unencrypted
   */
  if (window.location.protocol.startsWith('https'))
    protocol = 'wss://'

  /* We use the default server port if host is localhost */
  if (window.location.hostname === 'localhost')
    return protocol + 'localhost:3000/ws'

  else
    return protocol + window.location.host + '/ws'
}

function notifyListeners (listeners, msg) {
  var remaining_listeners = []

  /* Iterate on a (non-deep) clone of the listeners array. */
  listeners.slice(0).forEach(function (l) {
    var handled = (typeof l.cmd === 'function') ? l.cmd(msg) : l.cmd == msg.cmd

    if (handled) {
      try {
        handled = l.cb(msg,l.context)
      } catch (exception) {
        console.error(exception)
        handled = false
      }

      if (handled)
        console.debug('WebSocket: Dropping callback ' + l.cb.name + ' on ' + l.cmd)
    }

    /* Known to be faster than Array.push on some versions of
     * Firefox and others.
     */
    if (!handled)
    {
      console.log("callback not processed")
      console.log(msg)
      //console.log("type:"+(typeof l.cmd))
      //console.log("l.cmd:"+l.cmd+' | msg.cmd:'+msg.cmd)
      
      remaining_listeners[remaining_listeners.length] = l
    }
  })

  /* Some listeners can have been removed here. Others can have been
   * added or removed through callbacks calls. Merge with caution.
   */
  return listeners.filter(function (l) {
    return remaining_listeners.indexOf(l) !== -1
  })
}

function onWsMessage (listeners, msg) {
  var tokens = trim(msg.data).split(' '),
      protocol = tokens.shift()

  if (protocol === 'p1') {
    var parsed = {
      seq : tokens.shift(),
      src : tokens.shift(),
      dst : tokens.shift(),
      cmd : tokens.shift(),
      args : null
    }

    parsed.args = tokens.map(arg => {
      if (arg.indexOf('@') === 0)
        return Base64.decode(arg.substring(1))
      else
        return trimQuotes(arg)
    })

    /*
    if(parsed.cmd == 'message')
    console.log(parsed.args[2])*/
    //console.log(parsed)
    
    if (listeners._ != null)
    {
      console.log("sans source")
      listeners._ = notifyListeners(listeners._, parsed)
    }

    if (listeners[parsed.src] != null)
    {
      console.log("callback for :"+parsed.src)
      listeners[parsed.src] = notifyListeners(listeners[parsed.src], parsed)
    }
  }
}

const Connection = {
  setup : function (url) {
    this.listeners = []
    this.seq = 1
    this.url = url
    this.ws = null
  },

  close : function () {
    this.ws.close()
  },

  isOpen : function () {
    return this.ws != null && this.ws.readyState === 1
  },

  open : function (handlers) {
    try {
      console.debug('WebSocket: starting connection to ' + this.url + '...')

      this.ws = new WebSocket(this.url, 'lws-mirror-protocol')

      this.ws.onopen = (event) => {
        handlers.onWsOpen(event)
      }

      this.ws.onclose = handlers.onWsClose
      this.ws.onerror = handlers.onWsError
      this.ws.onmessage = msg => onWsMessage(this.listeners, msg)

    } catch (exception) {
      console.error(exception)
    }
  },

  registerListener : function (src, cmd, callback, context) {
    if (callback != null) {
      var key = src || '_'

      if (this.listeners[key] == null)
      {
        this.listeners[key] = []
      }

      /* Known to be faster than Array.push on some versions of
       * Firefox and others.
       */
      console.debug('WebSocket: Registering callback on ' + cmd)
      /*this.listeners[key][this.listeners[key].length] = {
        cmd: cmd,
        cb: callback
      }*/
      this.listeners[key].push({
        cmd: cmd,
        cb: callback,
        context: context
      })
    }
  },

  unregisterListener : function (src, cmd, callback) {
    if (callback != null) {
      var key = src || '_' 

      console.debug('WebSocket: unregistering callback: %s, %s, %s',src,cmd,callback)

      var tmpArray = this.listeners[key]
      console.log(tmpArray)
      tmpArray = tmpArray.filter(element => element.cmd != cmd)
      console.log(tmpArray)
      this.listeners[key] = tmpArray
    }
  }, 

  /**
   * Send a message through the websocket.
   *
   * @param {string} dst - The destination.
   * @param {string} command - The command.
   * @param {string} arg - Then any arguments
   */
  send : function () {
    if (this.ws && this.ws.readyState === 1) {
      var dst = arguments[0]
      var command = arguments[1]
      var data = ''

      /* The condition on command/io was introduced here for
       * command/io/zwave/get_parameters.
       */
      if (command !== 'login' && !command.startsWith('command/io')) {
        data = ' ' + Math.round(+new Date() / 1000)
      }
      for (var i = 2; i < arguments.length; i++) {
        data = data + ' @' + Base64.encode(arguments[i])
      }

      var msg = 'p1 ' + this.seq + ' ' + MICOCOL_CLIENT + ' ' + dst + ' ' + command + data

      this.ws.send(msg)
      this.seq++

    } else {
      console.warn('Error: network socket is down!')
    }
  },

  sendCommand : function (dst, cmd, args, listener) {
    if (listener != null) {
      this.registerListener(dst, listener.cmd, (msg) => {
        return listener.cb(msg, listener.self)
      })
    }

    this.send(dst, 'command/' + cmd, ...args)
  }
}

export default function get () {
  var cx = null

  if ('WebSocket' in window) {
    cx = Object.create(Connection)
    cx.setup(get_ws_url())
  } else {
    console.error('WebSocket are not available')
  }

  return cx
}
