import { Component, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import NetworkNav from './NetworkNav'
import Cartography from '../../components/Zdoctor/Cartography.js'
import ZigbeeChannelList from '../../components/Zdoctor/ZigbeeChannelList'
import ZigbeeChannelSelector from '../../components/Zdoctor/ZigbeeChannelSelector.js'
import { AssService } from '../../services/AssService.js'



function NetworkActions ({ i18n, t }) {
  const {gatewayId } = useParams()
  const {ezspVersion,setEzspVersion} = useState("")
  const {realName,setRealName} = useState("") 
  const {userId,setUserId} = useState("")

  const [focus, setFocus] = useState(document.hasFocus());

  useEffect(() => {
    // helper functions to update the status
    const onFocus = () => setFocus(true);
    const onBlur = () => setFocus(false);

    // assign the listener
    // update the status on the event
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    // remove the listener
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  /*
  useEffect(() => {
    if(focus)
    {
      AssService.getObject(gatewayId).then((result)=>{
        console.log(result)
        result.components.forEach((element)=>
        {
          if(element.typeName == "Zigbee_EZSP")
            setEzspVersion(element.firmware);
        })
        //setRealName(result.realName);
        //setUserId(result.user.resource.id)
      })  
    }
  }, [focus]);*/  

  return (
    <>
      <NetworkNav title={t('users.network.title')} />
      <div className="content">

        <div class="row cartography">
          <div class="col">
              <h6>Cartographie</h6>
              <Cartography gatewayId={gatewayId} /*ezspVersion={ezspVersion}*/></Cartography>
          </div>
        </div>

        <div class="row">
          <div class="col-8">
            <h6>Liste des canaux</h6>
            <ZigbeeChannelList gatewayId={gatewayId}></ZigbeeChannelList>    
          </div>

          <div class="col">
            <h6>Changement de canal</h6>
            <ZigbeeChannelSelector gatewayId={gatewayId}/>
          </div>
        </div>
      </div>  
    </>
  )
}

export default withTranslation('ui')(NetworkActions)
