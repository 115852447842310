import React, { useState, useEffect } from "react";
import useAuth from "../../utils/hooks/useAuth";
import { Link } from "react-router-dom";
import "./profile.css";

import { DurinClient } from "../../services/apiClient";

const Profile = () => {
    const client = new DurinClient('server/users')

	const { auth } = useAuth();
	const [gateways, setGateways] = useState([]);

	useEffect(() => {
		getUserGateways();
	}, []);

	const getUserGateways = async () => {
		client.get(auth.userId).then((res) => {
			const gateway = res?.data?.resource?.gatewayNames;
			setGateways(gateway);
		});
	};

	return (
		<div className="user__profile col-12">
			<div className="card">
				<div className="card__header">Profile</div>
				<div className="card__body">
					<div className="user__profile-details">
						<div className="user__info">
							<i className="bx bx-user user__icon">
								<span className="icon-description">Name :</span>
							</i>
							<div className="user__info-title">
								<p className="name-item">{auth.userName}</p>
							</div>
						</div>
						<div className="user__info">
							<i className="bx bx-log-in-circle user__icon">
								<span className="icon-description">Login :</span>
							</i>
							<div className="user__info-title">
								<p className=" login-item">{auth.login}</p>
							</div>
						</div>
						<div className="user__info">
							<i className="bx bx-accessibility user__icon">
								<span className="icon-description">Roles :</span>
							</i>
							<div className="user__info-title">
								{auth?.roles.map((role, index) => (
									<p className="role-item" key={index}>
										{role}
									</p>
								))}
							</div>
						</div>
						<div className="user__info">
							<i className="bx bx-station user__icon">
								<span className="icon-description">Gateways :</span>
							</i>
							<div className="user__info-title">
								{gateways.map((gate, index) => (
									<p className=" gateway-item" key={index}>
										{gate}
									</p>
								))}
							</div>
						</div>
					</div>

					<div className="edit-profile">
						<Link to={"/users/" + auth?.userId}>
							<button type="button" className="edit-button">
								Edit Profile
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Profile;
