import { Outlet } from 'react-router-dom'

import Sidebar from '../sidebar/Sidebar'
import TopNav from '../topnav/TopNav'

const Layout = () => {
	return (
		<div className="layout">
		  <Sidebar />
		  <TopNav />
          <div className='content'>
			<Outlet />
          </div>
	    </div>
	)
}

export default Layout
