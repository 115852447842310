import { Suspense, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Layout from './containers/layout/Layout'

import Dashboard from './pages/dashboard/Dashboard'
import Keys from './pages/keys/Keys'
import Login from './pages/login/Login'
import NotFound from './pages/NotFound'
import Profile from './pages/profile/Profile'
import Unauthorized from './pages/Unauthorized'
import Administrators from './pages/users/Administrators'
import UserObjects from './pages/users/UserObjects'
import UserProfile from './pages/users/UserProfile'
import Users from './pages/users/Users'
import UsersObjects from './pages/objects/UsersObjects'
import Firmwares from './pages/firmware/Firmwares'
import ThemeAction from './globalState/store/actions/ThemeAction'
import RequireAuth from './components/RequireAuth'

import useAuth from './utils/hooks/useAuth'
import UserNav from './pages/users/UserNav'
import NetworkNav from './pages/users/NetworkNav'
import NetworkActions from './pages/users/NetworkActions'
import NetworkAnalyze from './pages/users/NetworkAnalyze'

function App() {
  const themeReducer = useSelector((state) => state.ThemeReducer)
  const dispatch = useDispatch()

  const { auth } = useAuth()

  useEffect(() => {
    dispatch(ThemeAction.setMode(localStorage.getItem('themeMode', 'theme-mode-light')))
    dispatch(ThemeAction.setColor(localStorage.getItem('colorMode', 'theme-mode-light')))
  }, [dispatch])

  return (
    <div className={`app ${themeReducer.mode} ${themeReducer.color}`}>
      <Suspense fallback='loading'>
        {auth == null ? (
          <Login />
        ) : (
          <Routes>
            <Route path='/admin' element={<Login />} />
            <Route element={<Layout />}>
              {/* public routes */}
              <Route path='/unauthorized' element={<Unauthorized />} />

              {/* we want to protect these routes */}
              <Route
                element={
                  <RequireAuth
                    allowedRoles={['ROLE_ASS', 'ROLE_READ', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN']}
                  />
                }
              >
                <Route path='/dashboard' element={<Dashboard />}  />
                <Route path='/profile' element={<Profile />}  />
              </Route>
              <Route element={<RequireAuth allowedRoles={['ROLE_ASS', 'ROLE_SUPER_ADMIN']} />}>
                <Route path='/administrators' element={<Administrators />} />
                <Route path='/keys' element={<Keys />} />
                <Route path='/users' element={<Users />} />
                <Route path='/users/:userId/profile' element={<UserProfile />} component={UserNav}/>
                <Route path='/users/:userId/objects' element={<UserObjects />} component={UserNav}/>
                <Route path='/users/:userId/objects/network/:gatewayId/actions' element={<NetworkActions />} component={NetworkNav}/>
                <Route path='/users/:userId/objects/network/:gatewayId/analyse' element={<NetworkAnalyze />} component={NetworkNav}/>
                <Route path='/usersObjects' element={<UsersObjects />} />
                <Route path='/firmwares' element={<Firmwares />} />
              </Route>

              {/* catch all */}
              <Route path='*' element={<NotFound />} />
            </Route>
          </Routes>
        )}
      </Suspense>

    </div>
  )
}

export default App
