import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { AuthProvider } from './globalState/context/AuthProvider'
import rootReducer from './globalState/store/reducers'
import App from './App'

/* The followings are imported to be bundled. */

import './i18n'

const store = createStore(rootReducer)

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<AuthProvider>
					<Routes>
						<Route path='/*' element={<App />} />
					</Routes>
				</AuthProvider>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
)
