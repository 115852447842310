import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import useAuth from '../../utils/hooks/useAuth'
import SidebarItem from './SidebarItem'
import config from '../../config'

const Sidebar = (props) => {
	const { auth } = useAuth()
	const location = useLocation()

    const SIDEBAR_ROUTES = [
        {
            display_name: 'Dashboard',
            icon: 'bx bxs-dashboard',
            route: '/dashboard',
        },
        {
            display_name: 'Utilisateurs',
            icon: 'bx bxs-face',
            route: '/users',
        },
		{
            display_name: 'Produits',
            icon: 'bx bxs-cube',
            route: '/usersObjects',
        },
		{
            display_name: 'Mises à jour',
            icon: 'bx bxs-chevrons-up',
            route: '/firmwares',
        },
        // {
        //     display_name: 'Applications',
        //     icon: 'bx bxs-extension',
        //     route: '/applications',
        // },
        {
            display_name: 'Keys',
            icon: 'bx bx-key',
            route: '/keys',
        },
        // {
        //     display_name: 'Gateways',
        //     icon: 'bx bx-broadcast',
        //     route: '/gateways',
        // },

        {
            display_name: 'Comptes d\'administration',
            icon: 'bx bxs-user-badge',
            route: '/administrators',
        },
    ]

	const activeItem = SIDEBAR_ROUTES.findIndex(
		(item) => item.route === location.pathname
	)

	useEffect(() => {}, [auth])
	const roles = auth?.roles

	if (roles) {
		if (roles.includes('ROLE_SUPER_ADMIN') || roles.includes('ROLE_ADMIN')) {
			return (
				<div className='sidebar'>
				  <div className='sidebar-logo' />
				  <div className='server-name'>{config.server()}-{config.version}</div>
				  {SIDEBAR_ROUTES.map((item, index) => (
					  <Link to={item.route} key={index}>
						<SidebarItem
						  title={item.display_name}
						  icon={item.icon}
						  active={index === activeItem}
						/>
					  </Link>
				  ))}
				</div>
			);
		} else if (
			!roles.includes('ROLE_SUPER_ADMIN') ||
			    !roles.includes('ROLE_ADMIN')
		) {
			const sidebar_RolesBased_items = [];
			sidebar_RolesBased_items.push(SIDEBAR_ROUTES[0]);
			return (
				<div className='sidebar'>
				  <div className='sidebar-logo' />
				  <div className='server-name'>{config.server()}-{config.version}</div>
				  {sidebar_RolesBased_items.map((item, index) => {
					  if (item.display_name === 'Dashboard') {
						  return (
							  <Link to={item.route} key={index}>
								<SidebarItem
								  title={item.display_name}
								  icon={item.icon}
								  active={index === activeItem}
								/>
							  </Link>
						  )
					  } else {
						  return <div> </div>
					  }
				  })}
				</div>
			);
		}
	} else {
		return (
			<div className='sidebar'>
			  <div className='sidebar-logo' />
			  <div className='server-name'>{config.server()}-{config.version}</div>
			</div>
		);
	}
};

export default Sidebar
