import { VictoryGroup, VictoryLine } from 'victory'

const StatusCard = (props) => {
  const data = []

  if (props.data != null) {
    props.data.forEach(point => {
      data.push({
        timestamp: point.timestamp,
        value: point.value != null ? point.value : 0
      })
    })
  }

  return (
    <div className='status-card'>
      <div className='status-card-title-row'>
        <div className='status-card-icon'>
          <i className={props.icon}></i>
        </div>
        <div className='status-card-title'>{props.title}</div>
      </div>
      <div className='status-card-value'>{props.value}</div>
			{data.length !== 0 ?
       <div className='status-card-graph'>
         <VictoryGroup>
           <VictoryLine
             data={data}
             interpolation='catmullRom'
             x='timestamp'
             y='value'
           />
         </VictoryGroup>
       </div>
       : ""}
    </div>
  )
}

export default StatusCard
