import React from "react";
import Input from "./Input";
import Textarea from "./Textarea";
import Select from "./Select";
import RadioButtons from "./RadioButtons";
import CheckboxGroup from "./CheckboxGroup";
import DatePicker from "./DatePicker";

/**
 * * This FormikControl Component,
 * decides which  form field will be rendered based on one particular
 * prop. denoted "control".
 * It can render the following built components: Input, Textarea,
 * Select, RadioButtons, CheckboxGroup, and DatePicker .
 *@param {Object} props: parent properties to be shared with children.
 *
 * UseCase :  <FormikControl control="input" type="email" name="email" label="Email" />
 *        will render an Input custom Formik component(see Component Input to know more about the passed props.).
 * Remark:  The {...rest} props are passed in each return case because the
 *          retrieved property "control" contains the necessary arguments
 *          needed to implement a particular Field Component (eg. Input, Select etc.).
 *          See each  component to get how it works.
 */
export default function FormikControl(props) {
	const { control, ...rest } = props;
	switch (control) {
		case "input":
			return <Input {...rest} />;

		case "textarea":
			return <Textarea {...rest} />;
		case "select":
			return <Select {...rest} />;
		case "radio":
			return <RadioButtons {...rest} />;
		case "checkbox":
			return <CheckboxGroup {...rest} />;
		case "date":
			return <DatePicker {...rest} />;
		default:
			return null;
	}
}
