//import Popup from 'reactjs-popup';
import Modal from '@mui/material/Modal';
import {AssService} from '../../services/AssService'
import { useEffect, useState } from 'react';

/*--
    action[0] : pop state parameter
    action[1] : gateway id
    action[2] : function to call when gatewai is added
--*/
const GatewayRebootPopUp = ({action,t}) => {

    const [openPopup,setOpenPopup] = useState (0);

    useEffect(()=>{
            setOpenPopup(action[0]);
    },[action[0]])

    useEffect(()=>{
    },[openPopup])

    function onReboot() {
        try {
          var commandArgs = [
            {"value":"command/system/gateway/restart","name":"command"},
            ];  
  
          AssService.getObject(action[1]).then((result)=>{
            AssService.sendGatewayCommand(result.user.resource.id,action[1],commandArgs)
            action[2]()
          })  
        } catch (e) {
          throw new Error(`API error:${e?.message}`)
        }    
      }
  
      return (
        <Modal open={openPopup} className='confirmPopUp'
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <div id="modalContent" className='confirmPopUp-content'>
                <h5>{t('objects.rebooting.title')}</h5>
                <p>{t('objects.rebooting.text')}</p>
                <button className="button" onClick={()=>{action[0]=false}}>{t('objects.deleting.cancel')}</button>
                <button className="button" onClick={onReboot}>{t('objects.rebooting.validate')}</button>
            </div>
        </Modal>
    );
    
}
export default GatewayRebootPopUp;