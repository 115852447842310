import { useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'

import dates from '../../utils/dates'
import Table from '../../components/table'

import KeysPopup from './KeysPopup'
import KeyService from '../../services/KeyService'

function Keys ({ i18n, t }) {
  const [searchPattern, setSearchPattern] = useState('')

  const [editPopupContent, setEditPopupContent] = useState({})
  const [editPopupState, setEditPopupState] = useState(false)

  const tableRef = useRef(null)

  const queryClient = new QueryClient()

  const columns = useMemo(() => {
    const formatAuthorizations = (value) => {
      if (value != null)
        return value.join(', ')
      else
        return ''
    }

    return [
      {
        accessorKey: 'key',
        header: t('keys.table.header.key')
      },
      {
        accessorKey: 'comment',
        header: t('keys.table.header.comment')
      },
      {
        accessorKey: 'authorizations',
        cell: props => (formatAuthorizations(props.getValue())),
        header: t('keys.table.header.authorizations')
      },
      {
        accessorKey: 'created',
        cell: props => (dates.format(i18n, props.getValue())),
        header: t('keys.table.header.created')
      },
      {
        accessorKey: 'changed',
        cell: props => (dates.format(i18n, props.getValue())),
        header: t('keys.table.header.changed')
      },
      {
        accessorKey: 'status',
        header: t('keys.table.header.status')
      },
      {
        cell: props => (
        <div
          className='pointer'
          onClick={() => {onOpenEditor(props.row.original.id)}}>
          <p>{t('keys.table.details')}</p>
          <box-icon class='icon' name='plus-circle'></box-icon>
        </div>
        ),
        header: t('keys.table.header.edit')
      }
    ]
  }, [i18n, t])

  async function fetchData (parameters) {
    let query = {}

    if (parameters.pageFilter.trim().length > 0) {
      query['sift:value'] = parameters.pageFilter.trim()
    }

    (parameters.pageSortBy || []).forEach(sort => {
      query[`order:${sort.id}`] = sort.desc ? 'desc' : 'asc'
    })

    query['page'] = parameters.pageIndex + 1
    query['size'] = parameters.pageSize

    try {
      return await KeyService.getKeys(query)
    } catch (e) {
      throw new Error(`API error:${e?.message}`);
    }
  }

  function onCreateKeys (properties) {
    KeyService.createKeys(properties).then(resources => {
      tableRef.current.reload()
    })
  }

  function onEditKey (properties) {
    setEditPopupState(false)
    if (properties !== null) {
      KeyService.updateKey(properties.id, properties).then(resource => {
        tableRef.current.reload()
      })
    }
  }

  function onOpenEditor (id) {
    KeyService.getKey(id).then((resource) => {
      setEditPopupContent(resource)
      setEditPopupState(o => !o)
    }).catch((error) => {
      console.log(error)
    })
  }

  function onSearchUpdate (e) {
    setSearchPattern(e.target.value)
  }

  return (
    <>
      <h1>{t('keys.title')}</h1>
      <div className='head-tools'>
        <KeysPopup
          isCreation={true}
          callback={onCreateKeys}
          trigger={<button>{t('keys.edit.button')}</button>} />
        <KeysPopup
          isCreation={false}
          callback={onEditKey}
          control={editPopupState}
          values={editPopupContent} />
        <input
          onChange={onSearchUpdate}
          placeholder={t('keys.search.placeholder')}
          type='text'
          value={searchPattern}/>
      </div>
      <div>
        <QueryClientProvider client={queryClient}>
          <Table
            ref={tableRef}
            columns={columns}
            feeder={fetchData}
            keyword={searchPattern} />
        </QueryClientProvider>
      </div>
    </>
  )
}

export default withTranslation('ui')(Keys)
