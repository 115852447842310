import { useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import dates from '../../utils/dates'
import Table from '../../components/table'
import UserEditPopup from './UserEditPopup'

import UserService from '../../services/UserService'

function Users ({ i18n, t }) {
  const [searchPattern, setSearchPattern] = useState('')

  const tableRef = useRef(null)

  const queryClient = new QueryClient()

  const columns = useMemo(() => [
    {
      accessorKey: 'id',
      header: t('users.table.header.id')
    },
    {
      accessorKey: 'login',
      header: t('users.table.header.login')
    },
    {
      accessorKey: 'name',
      enableSorting: false,
      header: t('users.table.header.name')
    },
    {
      accessorKey: 'lastSeen',
      cell: props => (dates.format(i18n, props.getValue(), t('users.table.no_date'))),
      header: t('users.table.header.last_connection')
    },
    {
      cell: (props) => (
        <Link 
          className='pointer table-icon'
          to={'/users/' + props.row.original.id + '/profile'}>
          <span class="nav-text">{t('users.table.open')}</span>
          <box-icon class='icon arrow' name='right-arrow-alt'></box-icon>
        </Link>
      ),
      header: t('users.table.header.actions')
    }
  ], [i18n, t])

  async function usersFetcher (parameters) {
    let query = {}

    if (parameters.pageFilter.trim().length > 0) {
      query['sift:login'] = parameters.pageFilter.trim()
    }

    (parameters.pageSortBy || []).forEach(sort => {
      query[`order:${sort.id}`] = sort.desc ? 'desc' : 'asc'
    })

    query['page'] = parameters.pageIndex + 1
    query['size'] = parameters.pageSize

    /* Select only the users. */
    query['sift:role'] = 'READ'

    try {
      return await UserService.getUsers(query)
    } catch (e) {
      throw new Error(`API error:${e?.message}`);
    }
  }

  function onCreateUser (properties) {
    var newProperties = []
    properties.roles.forEach((role,index)=>{
      if(role.checked)
      {
        newProperties.push(role.value)
      }
    })
    properties.roles = newProperties;
    UserService.addUser(properties).then(resources => {
      tableRef.current.reload()
    })
  }

  function onSearchUpdate (e) {
    setSearchPattern(e.target.value)
  }

  return (
    <>
      <h1>{t('users.title')}</h1>
      <div className='head-tools'>
         <UserEditPopup
          isAdmin={false}
          isCreation={true}
          callback={onCreateUser}
          trigger={<button>{t('users.create.button')}</button>} />
        <input
          onChange={onSearchUpdate}
          placeholder={t('users.search.placeholder')}
          type='text'
          value={searchPattern}/>
      </div>
      <div>
        <QueryClientProvider client={queryClient}>
          <Table
            ref={tableRef}
            columns={columns}
            feeder={usersFetcher}
            keyword={searchPattern} />
        </QueryClientProvider>
      </div>
    </>
  )
}

export default withTranslation('ui')(Users)
