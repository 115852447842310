import { DurinClient } from './apiClient'

const OtaService = {
  client: new DurinClient(),

  getFirmwares: async function(criteria) {
    try {
      const response = await this.client.get('server/repository', criteria)
      return response
    } catch (error) {
      return []
    }
  },

   /**
   * Get a specific firmware.
   *
   * @param {Number} id : the unique identifier of the requested firmware.
   * @returns {Object} : expected firmware
   */
  getFirmware: async function(id) {
    try {
      const response = await this.client.get(['server/repository',id])
      return response
    } catch (error) {
      return []
    }
  },

     /**
   * Get firmware list for a dedicated type.
   *
   * @param {Array} type : targeted types or producs to update.
   * @returns {[Object]} : expected firmwares
   */
    getFirmwaresByType: async function(type) {
      var _firmwares = []
      try {
        const response = await this.client.get(['server/repository?size=25']) //TODO remplacer par ?all qd dispo côté serveur
        response.data.forEach(firmware => {
          var obj = this.getFirmwareDetails(firmware.filename)
          //console.log(obj)
          if (type.includes(obj[0]) || type.includes(obj[1]))
          {
            obj.push(firmware.id)
            _firmwares.push(obj)
          }
        })
        return _firmwares
      } catch (error) {
        return error
      }
    },

  /**
   * Return information about a firmware
   *
   * Expected format for a firmware name and path : 
   *    - /type/product/fimware_name-version.extension for production version
   *    - /type/product/subdirectory/fimware_name-version.extension for development version
   * 
   * @param {String} filename : the filename of the requested firmware.
   * @returns {Array} : [0] target type
   *                    [1] targeted product
   *                    [2] firmware version
   *                    [3] firmware for production use
   *                    [4] given name
   */
  getFirmwareDetails: function(filename)
  {
    var _type = ''
    var _product = 'undefined'
    var _version = ''
    var _isForProduction = false
    var _name = ''
    var _fileIndex = 3;

    if (filename === null)
      return [_type,_product,_version,_isForProduction,_name]

    var _preProcessing = filename.split('/');

    if (_preProcessing.length === 3 || _preProcessing.length === 4 || _preProcessing.length === 2)
    {
      _type = _preProcessing[0]

      if (_preProcessing.length > 2)
        _product = _preProcessing[1]

      if(_preProcessing.length === 3)
      {
        _isForProduction = true
        _fileIndex = _fileIndex -1;
      } 
      if(_preProcessing.length === 2)
      {
        _isForProduction = true
        _fileIndex = _fileIndex -2;
      } 

      [_name,_version] = this.splitFirmwareName(_preProcessing[_fileIndex])
    }
    else
    {
      //TODO 
    }

    return [_type,_product,_version,_isForProduction,_name]

  },
  
  splitFirmwareName: function(filename)
  { 

    var _name = 'invalid format'
    var _version = 'invalid format'

    if ( filename.lastIndexOf('.') > 0)
    {
      var _tmp = filename.slice(0,filename.lastIndexOf('.'))

      if(_tmp.lastIndexOf('-')>1)
      {
        _version = _tmp.slice(_tmp.lastIndexOf('-')+1)
        _name = _tmp.slice(0,_tmp.lastIndexOf('-'))
      }        
    }
    return [_name,_version]
  }
}

export default OtaService
