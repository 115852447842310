import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import config from '../../config'
import Select from 'react-select'
import {AssService} from '../../services/AssService'
//import { Visibility } from '@tanstack/react-table'

/*--
    action[0] : pop state parameter
    action[1] : userId
    action[2] : function to call when Popup is closed
--*/
const AddObjectPopUp = ({action,t}) => {
  
    const [openPopup,setOpenPopup] = useState (false);
    const [id, setId] = useState('');
    const [mac, setMac] = useState('')
    const [boardType, setBoardType] = useState('')
    const [VisibilityMac, setVisibilityMac] = useState(false)
    const [type, setType] = useState({})
    const [readOnly, setReadOnly] = useState(true)

    useEffect(()=>{
        if(openPopup === false && action[0] === true)
        {
            setType({});
            setId('');
            setMac('');
            setBoardType('');
            setVisibilityMac(false);
        }
        setOpenPopup(action[0]);
    },[action])

    function onAdd() {

        if (type.label === "BoardGate")
        {
            type.realName += boardType+"/"
        }

        var body = {realName: type.realName+id, typeName: type.value}

        if (type.label === "BoardGate")
        {
            body.parameters = [{"name":"mac_address","value":mac}]
        }


        try {
            return AssService.createObject(action[1],body).then(()=>{
                action[2]();
                setOpenPopup(false);
            })
        } catch (e) {
          throw new Error(`API error:${e?.message}`)
        }    
    }

    return (
        <Popup open={openPopup} closeOnDocumentClick modal className='addPopUp'>
            <h5>{t('objects.add.title')}</h5>
            <Select 
                className="filter"
                options={config.supported_objects()} 
                onChange={(e) => {console.log(e);setReadOnly(false);setType(e);if(e.parameters?.includes('mac_address')){setVisibilityMac(true)}else{setVisibilityMac(false)}}}
            />
            <input
                placeholder={t('objects.add.placeholder')}
                readOnly={readOnly}
                type='text'
                value={id}
                onChange={(e) =>setId(e.target.value)}
            />
            {VisibilityMac&&<input
                placeholder={t('objects.add.mac.placeholder')}
                readOnly={readOnly}
                type='text'
                value={mac}
                onChange={(e) =>setMac(e.target.value)}/>
            }
            {VisibilityMac&&
                <Select 
                    className="filter"
                    options={[{label: "Swing", value: "swing"},{label: "Sliding", value: "sliding"}]} 
                    onChange={(e) => {setBoardType(e.value)}}
                />
            }
            <button className="button" onClick={()=>{action[2]()}}>{t('objects.add.cancel')}</button>
            <button className="button" onClick={onAdd}>{t('objects.add.validate')}</button>
        </Popup>
    );
    
}
  
export default AddObjectPopUp;