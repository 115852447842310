import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import NetworkNav from './NetworkNav'
import ZigbeeRoutes from '../../components/Zdoctor/ZigbeeRoutes'



function NetworkAnalyze ({ i18n, t }) {
  const {gatewayId } = useParams() 
 

  return (
    <>
      <NetworkNav title={t('users.network.title')} />
      <div className="content">

        <div class="row">
          <div class="col">
              <h6>Infos zigbee</h6>

          </div>

          <div class="col">
            <h6>Routes</h6>
            <ZigbeeRoutes></ZigbeeRoutes>
          </div>
        </div>


        
      </div>  
    </>
  )
}

export default withTranslation('ui')(NetworkAnalyze)
