const ADMIN_ROLES = [
	{ key: 'ADMIN', value: 'ADMIN' },
	{ key: 'SUPER_ADMIN', value: 'SUPER_ADMIN' },
	{ key: 'ASS', value: 'ASS' }
]

const USER_ROLES = [
	{ key: 'READ', value: 'READ' },
	{ key: 'REGISTER', value: 'REGISTER' },
	{ key: 'CONFIG', value: 'CONFIG' },
	{ key: 'EXEC', value: 'EXEC' },
	{ key: 'SCHEDULE', value: 'SCHEDULE' },
	{ key: 'SCRIPT', value: 'SCRIPT' },
	{ key: 'CAMERA', value: 'CAMERA' },
	{ key: 'ASSOCIATION', value: 'ASSOCIATION' },
	{ key: 'CHILDREN', value: 'CHILDREN' },
	{ key: 'BOOLEAN_GRAPH', value: 'BOOLEAN_GRAPH' }
]

export { ADMIN_ROLES, USER_ROLES }
