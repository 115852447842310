import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../utils/hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
	const { auth } = useAuth();
	const location = useLocation();

	// Usage of the concat() function to ensure that the
	const userRoles = [].concat(auth?.roles);
	// We check if
	if (userRoles !== null) {
		return userRoles?.find((role) => allowedRoles?.includes(role)) ? (
			<Outlet />
		) : auth?.login ? (
			<Navigate to="/unauthorized" state={{ from: location }} replace />
		) : (
			<Navigate to="/" state={{ from: location }} replace />
		);
	} else {
		return <Navigate to="/" state={{ from: location }} replace />;
	}
};

export default RequireAuth;
