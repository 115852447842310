import { DurinClient } from './apiClient'

const UserService = {
    client: new DurinClient(),

	/**
	 * This function retrieves a user from the database
	 * @param {Number} userId : the user id
	 * @returns {Promise} : a Promise that points to the response from the request
	 */
	getUser: async function (userId) {
        return await this.client.get(['/server/users', userId])
	},

	/**
	 * This function retrieves all users from the database
	 * @param {*} page 
	 * @returns 
	 */
	getUsers: async function(criteria) {
        return await this.client.get('/server/users', criteria)
	},

	/**
	 * This function add a given user to the database
	 * @param {Object} values : a property that points towards form's values to be filled
	 * @returns {Promise} : a Promise that points to the response from the request
	 */
	addUser: async function (values) {
		try {
			const response = await this.client.post('/server/users', {
			    name: values.name,
			    login: values.login,
			    password: values.password,
			    roles: values.roles
		    })
			return response?.data
		} catch (error) {
			throw error
		}
	},

	/**
	 * This function updates a user with a given id from the database
	 * @param {Integer} userId : the user id
	 *  @param {Object} values : a property that points towards form's values to be filled
	 * @returns {Promise} : a Promise that points to the response from the request
	 */
	updateUser: async function (userId, values) {
		try {
			console.log(values)
			const response = await this.client.put(['/server/users', userId], {
			    login: values.login,
			    roles: values.roles,
		    })
			return response?.data
		} catch (error) {
			throw error
		}
	},
		/**
	 * This function updates a user password with a given id from the database
	 * @param {Integer} userId : the user id
	 *  @param {Object} values : password value
	 * @returns {Promise} : a Promise that points to the response from the request
	 */
		updateUserPassword: async function (userId, values) {
			try {
				console.log(values)
				const response = await this.client.put(['/server/users', userId], values)
				return response?.data
			} catch (error) {
				throw error
			}
		},
	/**
	 * This function deletes a given user from the database
	 * @param userId : The user id
	 * @returns a Promise that points to the response from the request
	 */
	deleteUser: async function (userId) {
		try {
			const response = await this.client.delete(['/server/users', userId])
			return response?.data
		} catch (error) {
			throw error
		}
	},

	saveUserDataToStorage: (token) => {
		localStorage.setItem('token', token)
	},

	removeUserDataFromStorage: () => {
		localStorage.setItem('token', null)
	},
}

export default UserService
