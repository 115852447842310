//import Popup from 'reactjs-popup';
import Modal from '@mui/material/Modal';
import {AssService} from '../../services/AssService'
import { useEffect, useState } from 'react';


const DeletePopUp = ({action,t}) => {

    const [openPopup,setOpenPopup] = useState (0);

    useEffect(()=>{
            setOpenPopup(action[0]);
    },[action[0]])

    useEffect(()=>{
        console.log(openPopup);
    },[openPopup])

    function onDelete() {
        try {
          console.log(action[0])
          return AssService.deleteObject(action[0]).then(()=>{action[1](0);/*setDummyState(Date.now());*/})
        } catch (e) {
          throw new Error(`API error:${e?.message}`)
        }    
      }
  
      return (
        <Modal open={openPopup} className='confirmPopUp'
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <div id="modalContent" className='confirmPopUp-content'>
                <h5>{t('objects.deleting.title')}</h5>
                <p>{t('objects.deleting.text')}</p>
                <button className="button" onClick={()=>{action[1](0)}}>{t('objects.deleting.cancel')}</button>
                <button className="button" onClick={onDelete}>{t('objects.deleting.validate')}</button>
            </div>
        </Modal>
    );
    
}
export default DeletePopUp;