import Popup from 'reactjs-popup'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import FormikControl from '../../components/formikComponents/FormikControl'
import * as Yup from 'yup'

import { ADMIN_ROLES, USER_ROLES } from '../../utils/userRoles'

import 'boxicons'

const UserEditComponent = ({isAdmin, isCreation, callback, control, t, trigger, values}) => {

  const [dummyState, setDummyState] = useState(0);
  const [userProperties,setUserProperties] = useState({});


  /* No validation for now */
  const validationSchema = Yup.object({
  })

  useEffect(()=>{

    if(!isCreation&&(Object.keys(values).length > 0))
    {
      var tempValues = {id:values.id,login:values.login,name:values.name}
      tempValues.roles = isAdmin ? ADMIN_ROLES.concat(USER_ROLES) : USER_ROLES
      
      tempValues.roles.map((role,index)=>{
        if(values.roles.includes(role.key))
          {
            tempValues.roles[index].checked = true;
          }
          else
          {
            tempValues.roles[index].checked = false;
          }
      })

      setUserProperties(tempValues);

    }
    else if (isCreation)
    {
      setUserProperties({roles:ADMIN_ROLES.concat(USER_ROLES)})
    }
    else
    {
      setUserProperties({});
    }
  },[control,values])
    

  /*
   * `trigger' is used by the creation popup, which is associated to a button.
   * The edit popup is controlled by a state, so it must notify its closing. It
   * does this using the given callback with no data.
   */
  return (
    <Popup closeOnDocumentClick modal nested open={control} /*onClose={() => {
      //!isCreation && callback(data)
    }}*/ trigger={trigger}>
      {close => (
        <div className='popup'>
          <h1 className='popup-title'>
            {t(isCreation ? 'users.popup.title.generate' : 'users.popup.title.edit')}
          </h1>
          <Formik
            initialValues={userProperties}
            validationSchema={validationSchema}
            onSubmit={(data) => {
              callback(data)
              close()
            }}>
            {(formik) => (
              <Form>
                <FormikControl
                  control='input'
                  label={t('users.popup.name')}
                  name='name'
                  type='text'
                  />

                <FormikControl
                  control='input'
                  label={t('users.popup.email')}
                  name='login'
                  type='email'/>

                <FormikControl
                  control='input'
                  label={t('users.popup.password')}
                  name='password'
                  type='password'/>



                <FormikControl
                  control='checkbox'
                  label={t('users.popup.roles')}
                  name='roles'
                  options={userProperties.roles}
                />


                <div className='button-row'>
                  <button type='button' className='secondary' onClick={close}>
                    {t('buttons.cancel')}
                  </button>
                  <button type='submit' className='button-ota'>
                    {t(isCreation ? 'buttons.generate' : 'buttons.save')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Popup>
  )
}

export default withTranslation('ui')(UserEditComponent)
