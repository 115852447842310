import React from "react";

/**
 *
 * @param {*} props : proprieties received from the parent.
 *
 * This component,
 * @returns : the error message for a particular rendered form component.
 */
export default function TextError(props) {
  return <div className="error">{props.children}</div>;
}
