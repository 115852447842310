import { useState, useEffect, useContext } from 'react';
import Popup from 'reactjs-popup';
import config from '../../config'
import Select from 'react-select'
import OtaService from '../../services/OtaService';
import {AssService,OtaWorker} from '../../services/AssService';
import { DurinClient } from '../../services/apiClient';
import AuthContext from '../../globalState/context/AuthProvider';

/*--
    action[0] : pop state parameter
    action[1] : gatewayId
    action[2] : function to call when upgrading
    action[3] : ezspObjectId
--*/
const OtaGatewayPopUp = ({action,t}) => {
  
    const [openPopup,setOpenPopup] = useState (false);
    const [idGW, setIdGW] = useState('');
    const [idNCP, setIdNCP] = useState('');
    const [firmwares, setFirmwares] = useState([]);
    const [firmwaresNCP, setFirmwaresNCP] = useState([]);
    const context = useContext(AuthContext)


    useEffect(()=>{
            setOpenPopup(action[0]);
            if (action[0] === true)
            {
                OtaService.getFirmwaresByType(['gateway']).then(results => { 
                    var options = []
                    for (let result of results)
                    {
                        options.push({label:result[4]+"-"+result[2],value:result[5]})
                    }
                    setFirmwares(options)
                })
                OtaService.getFirmwaresByType(['ncp']).then(results => { 
                    var options = []
                    for (let result of results)
                    {
                        options.push({label:result[4]+"-"+result[2],value:result[5]})
                    }
                    setFirmwaresNCP(options)
                })
            }
    },[action[0]])


    async function onUpdateGW() {
        
        //TODO erreur si pas de firmware choisi
        const firmware = await OtaService.getFirmware(idGW)

        console.log(firmware.filename)

        try {
          return AssService.sendOtaUpgradeRequest(config.server()+'/repository/'+firmware.filename,{ids:[action[1]]}).then(()=>{action[0] = false;action[2]()})
        } catch (e) {
          throw new Error(`API error:${e?.message}`)
        }    
    }

    async function onUpdateNCP() {
        
        //TODO erreur si pas de firmware choisi
        const firmware = await OtaService.getFirmware(idNCP)
        console.log(firmware.filename)


        var id = '';
        var result = await AssService.getObject(action[1]);

        result.components.forEach(element => {
            if (element.resource.typeName === 'Zigbee_EZSP')
            {
                id = element.resource.id;
            }
        });

        try {
            var client = new DurinClient()
            var otaWorker = new OtaWorker(client)
      
            otaWorker.queueDevices([id], config.server()+'/repository/'+firmware.filename, context.auth.socket)

        } catch (e) {
            throw new Error(`API error:${e?.message}`)
        }    
    }

    return (
        <Popup open={openPopup} closeOnDocumentClick modal className='addPopUp'>
            <h5>{t('users.gateways.ota.title')}</h5>
            <label className='popup-label'>
                {t('users.gateways.ota.choice.gateway')}
            </label>
            <Select 
                className="filter"
                options={firmwares} 
                onChange={(e) => {console.log(e.value);setIdGW(e.value)}}
            />
            <button className="button" onClick={()=>{setOpenPopup(false);action[2]()}}>{t('objects.add.cancel')}</button>
            <button className="button" onClick={onUpdateGW}>{t('objects.add.validate')}</button>
            <label className='popup-label'>
                {t('users.gateways.ota.choice.ncp')}
            </label>
            <Select 
                className="filter"
                options={firmwaresNCP} 
                onChange={(e) => {console.log(e.value);setIdNCP(e.value)}}
            />
            <button className="button" onClick={()=>{setOpenPopup(false);action[2]()}}>{t('objects.add.cancel')}</button>
            <button className="button" onClick={onUpdateNCP}>{t('objects.add.validate')}</button>
        </Popup>
    );
    
}
  
export default OtaGatewayPopUp;