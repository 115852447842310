import { DainClient } from './apiClient'

const AuthenticationService = {
    client: new DainClient(),

	/**
	 * Authenticate the user with the given credentials.
	 *
	 * @param {Object} values : a property that points towards form's values to be filled.
	 * @returns {Promise} : a Promise that points to the response from the request.
	 */
	authenticateUser: async function (values) {
		try {
            return await this.client.post('login', {
			    login: values.login,
			    password: values.password,
		    })
		} catch (error) {
			console.log(error)
			throw error
		}
	},

	/**
	 * Return data about the current user.
	 *
	 * @returns {Promise} : a Promise that points to the response from the request.
	 */
	getCurrentUser: async function () {
		try {
			return await this.client.get('login')
		} catch (error) {
			throw error
		}
	}
}

export default AuthenticationService
