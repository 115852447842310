import { useContext, useEffect, useState } from 'react'
import AuthContext from '../../globalState/context/AuthProvider';
import {AssService} from '../../services/AssService';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import get from '../../services/WsConnector';


const ZigbeeChannelSelector = ({gatewayId}) => {
    
    const context = useContext(AuthContext)
    const [channelNb,setChannelNb] = useState()
    const [scanState,setScanState] = useState(false)
    const [currentChannelNb,setCurrentChannelNb] = useState()
    const [realName,setRealName] = useState()
    const [hasConfig,setHasConfig] = useState(false)

    const marks = [
      {
        value: 11,
        label: '11',
      },
      {
        value: 25,
        label: '25',
      },
    ];

    function onConfigReceived(event){
      if(event.args[1] == "event/io/ezsp/dev-0/self/config_channel")
      {
        console.log("canal:"+event.args[2])
        setChannelNb(event.args[2])
        setCurrentChannelNb(event.args[2])
        setHasConfig(true)
        return true
      }

      return false
    }

    function setNewChannelNb()
    {    
        AssService.getObject(gatewayId).then((result)=>{
          //context.auth.socket.registerListener(result.realName,'status',onChannelListReceived)

          console.log(currentChannelNb)
          var commandArgs = [
            {"value":"command/io/ezsp/network","name":"command"},
            {"value":"dev-0/self","name":"arg1"},
            {"value":"channel","name":"arg2"},
            {"value":currentChannelNb,"name":"arg3"}
            ];  
          AssService.sendGatewayCommand(result.user.resource.id,gatewayId,commandArgs)
          setHasConfig(false)
        })      
    }

    useEffect(
      function getChannelNb()
      {   
        
        if(!hasConfig) 
        { 
          console.log("getChannel")
          AssService.getObject(gatewayId).then((result)=>{
            setRealName(result.realName)
            context.auth.socket.registerListener(result.realName,'status',onConfigReceived)
            context.auth.socket.registerListener(result.realName,'event',onConfigReceived)
            //context.auth.socket.registerListener(result.realName,null,onConfigReceived)


            var commandArgs = [
              {"value":"command/io/ezsp/refresh","name":"command"},
              {"value":"dev-0/self","name":"arg1"}
              ];  
            AssService.sendGatewayCommand(result.user.resource.id,gatewayId,commandArgs)
          })
        }      
      },[hasConfig])


    return(<>
          <Box
            alignItems="center"
            gap={4}
            p={2}
          >
            <div>Canal actuel: {channelNb}</div>
            <Slider
              track={false}
              marks={marks}
              step={1}
              min={11}
              max={25}
              valueLabelDisplay="auto"
              value={currentChannelNb}
              onChange={(event)=>{console.log(event.target);setCurrentChannelNb(event.target.value)}}
            />
            <button className={scanState?'cartography_btn onAir':'cartography_btn'} onClick={setNewChannelNb}>Modifier le canal</button>
        </Box>
   </>);
}
export default ZigbeeChannelSelector