import { useEffect, useState } from 'react'
import { Link, NavLink, useParams} from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import UserService from '../../services/UserService'

function UserNav ({ i18n, t, title }) {
    const { userId } = useParams()
    const [user, setUser] = useState(null)

    useEffect(() => {
        UserService.getUser(userId).then((resource) => {
            setUser(resource)
        }).catch((error) => {
            console.log(error)
        })
    }, [userId])

	return (
        <>
          <h2>
		    <Link to={'/users'}><box-icon name='arrow-back'></box-icon></Link>
            { title }&nbsp;
            { (user?.name != null) ? user.name : (user?.login != null ? user.login : '...') }
          </h2>
		  <div className='user-nav'>
		    <NavLink to={'/users/' + userId + '/profile'}>{t('users.nav.profile')}</NavLink>
            <NavLink to={'/users/' + userId + '/objects'}>{t('users.nav.objects')}</NavLink>
		  </div>
        </>
	)
}

export default withTranslation('ui')(UserNav)
