import Popup from 'reactjs-popup'
import { withTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import FormikControl from '../../components/formikComponents/FormikControl'
import * as Yup from 'yup'

import KeysAuthorizations from './KeysAuthorizations'

import 'boxicons'

const KeysEditComponent = ({isCreation, callback, control, t, trigger, values}) => {

  /* No validation for now */
  const validationSchema = Yup.object({
  })

  /* Set up the initial values if called without them. */
  if (values === undefined)
    values = {}

  if (values.authorizations === undefined)
    values.authorizations = []
  else
    values.authorizations = values.authorizations.filter(value => value != null && value.length > 0)

  values.comment = values.comment || ''
  values.number = values.number || 1

  /*
   * `trigger' is used by the creation popup, which is associated to a button.
   * The edit popup is controlled by a state, so it must notify its closing. It
   * does this using the given callback with no data.
   */
  return (
    <Popup closeOnDocumentClick modal nested open={control} onClose={() => {
      !isCreation && callback(null)
    }} trigger={trigger}>
      {close => (
        <div className='popup'>
          <h1 className='popup-title'>
            {t(isCreation ? 'keys.popup.title.generate' : 'keys.popup.title.edit')}
          </h1>
          <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={(data) => {
              callback(data)
              close()
            }}>
            {(formik) => (
              <Form>
                {isCreation &&
                 <FormikControl
                   control='input'
                   label={t('keys.popup.number')}
                   type='input'
                   name='number'/>
                }

                <FormikControl
                  control='input'
                  label={t('keys.popup.comment')}
                  type='input'
                  name='comment'/>

                <FormikControl
                  control='checkbox'
                  label={t('keys.popup.authorizations')}
                  name='authorizations'
                  options={KeysAuthorizations}/>

                <div className='button-row'>
                  <button type='button' className='secondary' onClick={close}>
                    {t('buttons.cancel')}
                  </button>
                  <button className='deleteBox'type='submit'>
                    {t(isCreation ? 'buttons.generate' : 'buttons.save')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Popup>
  )
}

export default withTranslation('ui')(KeysEditComponent)
