import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

import FormikControl from '../../components/formikComponents/FormikControl'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import AuthenticationService from './../../services/AuthenticationService'
import UserService from './../../services/UserService'
import ErrorMessage from '../../components/ErrorMessage'
import useAuth from './../../utils/hooks/useAuth'

function Login({ t }) {
  const { auth, login } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/dashboard'

  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    if (auth.login != null)
    {
      navigate(from, { replace: true })
    }
  }, [auth, from, navigate]);

  const validationSchema = Yup.object({
    login: Yup
      .string()
      .email(t('ui.login.constraint.email'))
      .required(t('ui.login.constraint.required')),

    password: Yup
      .string()
      .required(t('ui.password.constraint.required'))
      .min(6, t('ui.password.constraint.min_size'))
    /*.test('isValidPass', ' is not valid', (value, context) => {
      const hasUpperCase = /[A-Z]/.test(value)
      const hasLowerCase = /[a-z]/.test(value)
      const hasNumber = /\d/.test(value)
      const hasSymbole = /[!@#%&]/.test(value)
      let validConditions = 0
      const numberOfMustBeValidConditions = 3
      const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole]
      conditions.forEach((condition) =>
      condition ? validConditions++ : null
      )
      if (validConditions >= numberOfMustBeValidConditions) {
      return true
      }
      return false
      })*/,
  })

  const onSubmit = async (values) => {
    AuthenticationService
      .authenticateUser(values)
      .then((response) => {
        const email = response?.login
        const roles = response?.roles
        const userId = response?.userId
        const userName = response?.userName
        const token = response?.token

        if (token != null) {
          UserService.saveUserDataToStorage(token)
          login(email, roles, userId, userName)
          navigate(from, { replace: true })
        }
      })
      .catch((err) => {
        if (!err.response) {
          setErrMsg(t('error.no_response'))
        } else if (err.response?.status === 400) {
          setErrMsg(t('error.invalid_format'))
        } else if (err.response?.status === 401) {
          setErrMsg(t('error.unauthorized'))
        } else if (err.response?.status === 403) {
          setErrMsg(t('error.forbidden'))
        } else if (err.response?.status === 504) {
          setErrMsg(t('error.timeout'))
        } else {
          setErrMsg(t('error.internal'))
          console.log('Login Error:', err)
        }
      })
  }

  return (
    <div className='login-page'>
      <div className='login-header'>
        <div className='login-header-layer'>
          <div className='login-logo' />
          <p className='login-title'>{t('ui.title')}</p>
        </div>
      </div>
      <div className='login-form'>
        <div className='login-form-layer'>
          <Formik
            initialValues={{ login: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {(formik) => (
              <div>
                <Form className='login-form'>
                  <FormikControl
                    control='input'
                    type='text'
                    label={t('ui.login.label')}
                    name='login'
                    placeholder={t('ui.login.placeholder')}
                  />
                  <FormikControl
                    control='input'
                    type='password'
                    label={t('ui.password.label')}
                    name='password'
                    className='formikControl'
                    placeholder={t('ui.password.placeholder')}
                  />
                  <button type='submit'>
                    {t('ui.button.label')}
                  </button>
                </Form>
                <ErrorMessage errMsg={errMsg} />
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default withTranslation('login')(Login)
