const NOTIFICATIONS = [
	{
		icon: "bx bx-error",
		content: "Une action programmée a échoué",
	},
	{
		icon: "bx bx-error",
		content: "Notifications sur mobile désactivées",
	},
	{
		icon: "bx bx-error",
		content: "Portier VDP22300",
	},
	{
		icon: "bx bx-error",
		content: "Portier demo_vdp_1",
	},
];

export default NOTIFICATIONS;
