import { useMemo, useState, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import dates from '../../utils/dates'
import Table from '../../components/table'
import OtaService from '../../services/OtaService'
import Select from 'react-select'
import config from '../../config'
import Radio from '@mui/material/Radio';
import MassOTAPopUp from '../../components/popups/MassOTAPopUp'
import AddOTAPopUp from '../../components/popups/AddOTAPopUp'

function Firmwares ({ i18n, t }) {

    const [searchPattern, setSearchPattern] = useState('')
    const [selectedValue, setSelectedValue] = useState('');
    const [otaPopupState, setOtaPopupState] = useState(false);
    const [addOtaPopupState, setAddOtaPopupState] = useState(false);
    const tableRef = useRef(null)
    const options = config.supported_objects;

    const queryClient = new QueryClient()

    const columns = useMemo(() => [
        {
          cell: props => (
            <Radio
              checked={(selectedValue === props.row.original.id)}
              onClick={() => handleChange(props.row.original.id)}
              value={props.row.original.id}
              name="radio-buttons"
              inputProps={{ 'aria-label': props.row.original.id }}
              size="small"
            />
          ),
          header: " "
        },
        {
          accessorKey: 'id',
          header: "ID"
        },
        {
          cell: props => (OtaService.getFirmwareDetails(props.row.original.filename)[1]),
          header: t('firmwares.table.header.product')
        },
        {
          cell: props => (OtaService.getFirmwareDetails(props.row.original.filename)[0]),
          header: t('firmwares.table.header.type')
        },
        {
          cell: props => (OtaService.getFirmwareDetails(props.row.original.filename)[4]),
          header: t('firmwares.table.header.name')
        },
        {
          cell: props => (OtaService.getFirmwareDetails(props.row.original.filename)[2]),
          header: t('firmwares.table.header.version')
        },
        {
          accessorKey: 'lastModified',
          //cell: props => (dates.format(i18n, props.getValue()*1000, t('users.table.no_date'))),
          cell: props => (new Intl.DateTimeFormat('fr', { dateStyle: 'short', timeStyle: 'short' }).format(
            new Date(Number(props.getValue())))),
          header: t('firmwares.table.header.last_connection')
        },
        {
          cell: props => (OtaService.getFirmwareDetails(props.row.original.filename)[3]? <box-icon class='connection' color="#afe37b" type="solid" name="like" /> : <box-icon class='connection' color="red" type="solid" name="no-entry" />),
          header: t('firmwares.table.header.production')
        }
      ], [i18n, t, selectedValue])

    
    async function firmwaresFetcher (parameters) {
        let query = {}

        if (parameters.pageFilter.trim().length > 0) {
            query['sift:name'] = parameters.pageFilter.trim()
        }

        (parameters.pageSortBy || []).forEach(sort => {
            query[`order:${sort.filename}`] = sort.desc ? 'desc' : 'asc'
        })

        query['page'] = parameters.pageIndex + 1
        query['size'] = parameters.pageSize


        try {
          var response =  await OtaService.getFirmwares(query) 
          console.log(response)
          return response
        } catch (e) {
            throw new Error(`API error:${e?.message}`);
        }
    }

    const onSearchUpdate = (e) => {
      setSearchPattern(e.target.value)
    }

    function handleChange(id) {
      console.log(id)
      setSelectedValue(id);
    };

    function onPopupExit() {
      setSelectedValue('');
      setOtaPopupState(false);
    }

    return(
        <>
            <MassOTAPopUp action={[otaPopupState,selectedValue,onPopupExit]} t={t} />
            <AddOTAPopUp action={[addOtaPopupState,selectedValue,onPopupExit]} t={t} />
            <h2>{t('firmwares.title')}</h2>
            <div id="tools" className='ota_filter'>
                <input
                  onChange={onSearchUpdate}
                  placeholder={t('objects.search.placeholder')}
                  type='text'
                  value={searchPattern}
                />
                <button className='floating-right button-ota' onClick={()=>{}}>
                    {t('firmwares.button.add')}
                </button>
                <button className='floating-right  button-ota' onClick={()=>{
                      if (selectedValue === '')
                      {
                        alert(t('firmwares.message.alert'));
                      }
                      else
                      {
                        setOtaPopupState(true)
                      }
                    }}>
                    {t('firmwares.button.mass_ota')}
                </button>
                <span className='floating-cancel'></span>
            </div>
            <div id="firmare_list">
                <QueryClientProvider client={queryClient}>
                    <Table
                        ref={tableRef}
                        columns={columns}
                        feeder={firmwaresFetcher}
                        keyword={searchPattern} 
                    />
                </QueryClientProvider>
            </div>
        </>
    )
}
export default withTranslation('ui')(Firmwares)