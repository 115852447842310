import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import Tabs from "../../components/Tabs/Tabs"
import config from '../../config'
import Select from 'react-select'
import OtaService from '../../services/OtaService';
import AssService from '../../services/AssService';

/*--
    action[0] : pop state parameter
    action[1] : gateway object
    action[2] : function to call when upgrading
--*/
const DetailsGatewayPopUp = ({action,t}) => {
  
    const [openPopup,setOpenPopup] = useState (false);
    const [gateway, setGateway] = useState({});
    const [firmwares, setFirmwares] = useState([]);

    useEffect(()=>{
            setOpenPopup(action[0]);
            if (action[0] === true)
            {
                setGateway(action[1])
                /*AssService.getObject(action[1]).then(result => {

                })*/
            }
    },[action[0]])


    async function onCloseModal() {
        try {
          action[0] = false;
          action[2]();
        } catch (e) {
          throw new Error(`API error:${e?.message}`)
        }    
    }

    return (
        <Popup open={openPopup} closeOnDocumentClick modal onClose={onCloseModal}>
            <div className='popup'>
                <h5>{t('users.gateways.details.title')}</h5>
                <Tabs>
                    <div label={t('objects.details.menus.information')} icon="detail">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                        <h5>{t('objects.table.header.name')}</h5>
                                        <span>{gateway.realName}</span>
                                    </div>
                                </div>
                            <div className="row">
                                <div className="col">
                                    <h5>{t('objects.details.id')}</h5>
                                    <span>{gateway.id}</span>
                                </div>
                                <div className="col">
                                    <h5>{t('objects.details.user_name')}</h5>
                                    <span>{gateway.userName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div label={t('objects.details.menus.history')} icon="calendar">
                        <div className="container">
                        </div>
                    </div>
                    <div label={t('users.gateways.details.menus.dongles')} icon="network-chart">
                        <div className="container">
                            <div className="row">
                                <div className="col-1">
                                    ID
                                </div>
                                <div className="col-5">
                                    Nom
                                </div>
                                <div className="col">
                                    Type
                                </div>
                                <div className="col">
                                    OTA
                                </div>
                            </div>
                            {gateway.components&&gateway.components.map((dongle,index)=>{
                                return(
                                    <div id={dongle.resource.id} className="row">
                                        <div className="col-1">
                                            {dongle.resource.id}
                                        </div>
                                        <div className="col-5">
                                            {dongle.resource.userName}
                                        </div>
                                        <div className="col">
                                            {dongle.resource.typeName}
                                        </div>
                                        <div className="col">
                                            {(dongle.resource.typeName === "Zigbee_EZSP")&&<><button>Mettre à jour</button></>}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Tabs>
            </div>
        </Popup>
    );
    
}
  
export default DetailsGatewayPopUp;