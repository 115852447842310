import { useContext, useEffect, useState } from 'react'
import config from '../../config';
import {OtaWorker, AssService} from '../../services/AssService';
import { DurinClient } from '../../services/apiClient';
import AuthContext from '../../globalState/context/AuthProvider';
import Select from 'react-select'
import OtaService from '../../services/OtaService';


const OTA = ({objectId,t}) => {
  
  const context = useContext(AuthContext)
  const [otaType,setOtaType] = useState('none');
  const [otaUrl,setOtaUrl] = useState('');
  const [object,setObject] = useState({});
  const [selectOptions,setSelectOptions] = useState([]);
  const [selectedValue,setSelectedValue] = useState('');

  useEffect(()=>{
    AssService.getObject(objectId).then((result)=>{
      setObject(result);
    });
  },[objectId])

  useEffect(()=>{
    if(Object.keys(object).length > 0)
    {
      var objectConfig = config.supported_objects().find(element=>element.label==object.typeName);
      if(objectConfig != undefined)
      {
        setOtaType(objectConfig.ota)

        if (objectConfig.ota != 'none')
        {
          OtaService.getFirmwaresByType(object.typeName).then(results => { 
            var options = []
            for (let result of results)
            {
                options.push({label:result[4]+"-"+result[2],value:result[5]})
            }
            console.log(options)
            setSelectOptions(options)
        })
        }
      }
    }
  },[object])

  function startOta()
  {
    console.log(otaType)
    console.log(otaUrl)
    if(otaType == 'zigbee')
    {
      var client = new DurinClient()
      var otaWorker = new OtaWorker(client)

      otaWorker.queueDevices([object.id], otaUrl, context.auth.socket)
    }
    else if (otaType == 'http')
    {
      var criteria = {ids:[object.id]}
      AssService.sendOtaUpgradeRequest(otaUrl,criteria)
    }

    alert("l'OTA a démarré");
    setOtaUrl('');
    setSelectedValue('');

  }

    return(
      <>
        { 
          (otaType=='zigbee'||otaType=='http') && <div>
          <p>URL du firmware {otaType}</p>
          {(otaType=='http')&&<input
              placeholder={t('objects.ota.url')}
              type='text'
              value={otaUrl}
              onInput={e => setOtaUrl(e.target.value)}
          />}
          {(otaType=='zigbee')&&<Select 
                className="filter"
                options={selectOptions} 
                value={selectedValue}
                onChange={(e) => {
                  OtaService.getFirmware(e.value).then(data=>{
                    console.log(data)
                    setSelectedValue(data.label)
                    setOtaUrl(config.server()+'/repository/'+data.filename)
                  })
                }}
          />}
          <p>
              <button className="button-ota" onClick={() => {startOta()}}>{t('objects.upgrade')}</button>
          </p>
          </div>
        }
      </>
    );
}
export default OTA