const dates = {
  format: function (i18n, value, defaultMessage) {
    const timestamp = parseInt(value)

    if (value !== 0) {
      /* This is to take care of the old String format. */
      if (timestamp == value && timestamp < 10000000000) {
        return new Intl.DateTimeFormat(i18n.language, {
          dateStyle: 'short',
          timeStyle: 'short'
        }).format(new Date(timestamp * 1000))
      } else if (timestamp > 10000000000)
      {
        return new Intl.DateTimeFormat(i18n.language, {
          dateStyle: 'short',
          timeStyle: 'short'
        }).format(new Date(timestamp))
      } 
      else {
        return value
      }
    } else {
      return defaultMessage
    }
  }
}

export default dates
