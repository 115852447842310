import React, { useRef } from "react";
import useAuth from "../../utils/hooks/useAuth";

const clickOutsideRef = (content_ref, toggle_ref) => {
	document.addEventListener("mousedown", (e) => {
		// user click toggle
		if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
			content_ref.current.classList.toggle("dropdown-active");
		} else {
			// user click outside toggle and content
			if (content_ref.current && !content_ref.current.contains(e.target)) {
				content_ref.current.classList.remove("dropdown-active");
			}
		}
	});
};

const Dropdown = (props) => {
	const dropdown_toggle_el = useRef(null);
	const dropdown_content_el = useRef(null);
	const { auth } = useAuth();
	clickOutsideRef(dropdown_content_el, dropdown_toggle_el);

	const setActiveMenu = () =>
		dropdown_content_el.current.classList.add("dropdown-active");

	return (
		<div className="dropdown">
			<button
				ref={dropdown_toggle_el}
				onClick={() => setActiveMenu()}
			>
		      {props.icon ? <i className={[props.icon, 'dropdown-toggle-icon'].join(' ')}></i> : ""}
				{props.badge ? (
					<span className="dropdown-toggle-badge">{props.badge}</span>
				) : (
					""
				)}
				{props.customToggle ? props.customToggle() : ""}
			</button>
			<div ref={dropdown_content_el} className="dropdown-content">
				{props.showUser ? <p className="show-user">{auth.login}</p> : ""}
				{props.contentData && props.renderItems
					? props.contentData.map((item, index) =>
							props.renderItems(item, index)
					  )
					: ""}
				{props.renderFooter ? (
					<div className="dropdown-footer">{props.renderFooter()}</div>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default Dropdown;
